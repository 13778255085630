<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Stat from "@/components/widgets/stat";
import TopUsers from './top-users';
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    TopUsers,
  },
  props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
  data() {
    return {
      title: "Tableau de bord",
      items: [
        {
          text: "Accueil",
          to: "/"
        },
        {
          text: "Tableau de bord",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <TopUsers />
        <Stat v-if="showAdmin || showSuperSales" />
    </div>
  </Layout>
</template>