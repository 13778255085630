<template>
    <div class="col-xl-12">
        <div class="row">
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <h4 class="mb-1 mt-1">
                                <span data-plugin="counterup">
                                    <countTo :startVal="0" :endVal="stats.revenue.current" :duration="2000" decimal="," separator=" "></countTo>
                                </span>
                                €
                            </h4>
                            <p class="text-muted mb-0">Chiffre d'affaires</p>
                        </div>
                        <ProgressText :value="stats.revenue.progress" />
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <h4 class="mb-1 mt-1">
                                <span data-plugin="counterup">
                                    <countTo :startVal="0" :endVal="stats.salesCount.current" :duration="2000" decimal="," separator=" "></countTo>
                                </span>
                            </h4>
                            <p class="text-muted mb-0" v-if="stats.salesCount.current > 1">Ventes</p>
                            <p class="text-muted mb-0" v-else>Vente</p>
                        </div>
                        <ProgressText :value="stats.salesCount.progress" />
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <h4 class="mb-1 mt-1">
                                <span data-plugin="counterup">
                                    <countTo :startVal="0" :endVal="stats.customersTotal.current" :duration="2000" decimal="," separator=" "></countTo>
                                </span>
                            </h4>
                            <p class="text-muted mb-0" v-if="stats.customersTotal.current > 1">Nouveaux clients</p>
                            <p class="text-muted mb-0" v-else>Nouveau client</p>
                        </div>
                        <ProgressText :value="stats.customersTotal.progress" />
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <h4 class="mb-1 mt-1">
                                <span data-plugin="counterup">
                                    <countTo :startVal="0" :endVal="stats.cancelPercent.current" :duration="2000" decimal="," separator=" "></countTo>
                                </span>%
                            </h4>
                            <p class="text-muted mb-0">Taux d'annulation</p>
                        </div>
                        <ProgressText :value="stats.cancelPercent.progress" />
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <h4 class="mb-1 mt-1">
                                <span data-plugin="counterup">
                                    <countTo :startVal="0" :endVal="stats.averageCart.current" :duration="2000" decimal="," separator=" "></countTo>
                                </span>€
                            </h4>
                            <p class="text-muted mb-0">Panier moyen</p>
                        </div>
                        <ProgressText :value="stats.averageCart.progress" />
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <h4 class="mb-1 mt-1">
                                <span data-plugin="counterup">
                                    <countTo :startVal="0" :endVal="stats.transformationRate.current" :duration="2000" decimal="," separator=" "></countTo>
                                </span>%
                            </h4>
                            <p class="text-muted mb-0">Taux de transformation</p>
                        </div>
                        <ProgressText :value="stats.transformationRate.progress" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import countTo from "vue-count-to";
    import api from '../../api/api';
    import Utilities from "../../services/utilities";
    import ProgressText from "../../components/progress-text";
    
    /**
     * Stat component
     */
    export default {
        components: {
            countTo,
            ProgressText
        },
        data() {
            return {
                stats: {
                    "salesCount": {
                        "current": 0,
                        "progress": 0
                    },
                    "transformationRate": {
                        "current": 0,
                        "progress": 0
                    },
                    "revenue": {
                        "current": 0,
                        "progress": 0
                    },
                    "cancelPercent": {
                        "current": 0,
                        "progress": 0
                    },
                    "averageCart": {
                        "current": 0,
                        "progress": 0
                    },
                    "customersTotal": {
                        "current": 0,
                        "progress": 0
                    }
                }
            };
        },
        mounted() {
            this.loadStats();
        },
        methods: {
            loadStats() {
                api
                    .get('/stats')
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                this.stats = response.data;
                            } else {
                                Utilities.errormsg();
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            },
        }
    }
</script>