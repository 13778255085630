<template>
<div class="col-xl-12">
    <div class="card">
        <div class="card-body">
            <h4 class="card-title mb-4">Top des commerciaux</h4>

            <div data-simplebar style="max-height: 336px;">
                <div class="table-responsive">
                    <table class="table table-borderless table-centered table-nowrap mb-1">
                        <thead>
                            <tr>
                                <th>
                                    Rang
                                </th>
                                <th colspan="2">
                                    Nom
                                </th>
                                <th class="text-end">
                                    CA R1
                                </th>
                                <th class="text-end">
                                    CA REF
                                </th>
                                <th class="text-end">
                                    CA VA
                                </th>
                                <th class="text-end">
                                    R1
                                </th>
                                <th class="text-end">
                                    REF
                                </th>
                                <th class="text-end">
                                    Tx entrée
                                </th>
                                <th class="text-end">
                                    Transf.
                                </th>
                                <th class="text-end">
                                    Annul.
                                </th>
                                <th class="text-end">
                                    <i class="fa fa-shopping-cart me-1"></i> moyen
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in users" :key="index">
                                <td>
                                    <h6 class="font-size-15 fw-normal">{{ index +  1}}</h6>
                                </td>
                                <td style="width: 20px;">
                                    <div class="avatar-xs d-inline-block me-2">
                                        <span class="avatar-title rounded-circle">{{ item.firstname.charAt(0) }}{{ item.lastname.charAt(0) }}</span>
                                    </div>
                                </td>
                                <td>
                                    <h6 class="font-size-15 fw-normal">{{ item.firstname }} {{ item.lastname }}</h6>
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    <!-- <i class="icon-xs icon me-2 text-success" data-feather="trending-up"></i> -->
                                    {{ item.revenueR1 | price }}
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    {{ item.revenueREF | price }}
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    {{ item.revenueVA | price }}
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    {{ item.countR1 }}
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    {{ item.countREF }}
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    {{ item.entryRate | percent }}
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    {{ item.transformationRate | percent }}
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    {{ item.cancelPercent | percent }}
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                    {{ item.averageCart | price }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import api from '@/api/api';
    import Utilities from "@/services/utilities";
    
    /**
     * Top-users component
     */
    export default {
        data() {
            return {
                users: []
            };
        },
        mounted() {
            this.loadStats();
        },
        methods: {
            loadStats() {
                    api
                        .get('/users/stats')
                        .then(
                            (response) => {
                                if (response && response.status == 200) {
                                    this.users = response.data;
                                } else {
                                    Utilities.errormsg();
                                }
                            },
                            () => {
                                Utilities.errormsg();
                            }
                        )
                },
        }
    };
</script>