<template>
    <p class="text-muted mt-3 mb-0">
        <span class="text-success me-1" v-if="value > 0">
            <i class="mdi mdi-arrow-up-bold me-1"></i>{{ value | percent }}
        </span>
        <span class="text-danger me-1" v-if="value <= 0 && value != -100">
            <i class="mdi mdi-arrow-down-bold me-1" v-if="value < 0"></i>{{ value | percent }}
        </span>
        <span v-if="value == -100">N/A</span>
        <br>
        par rapport au mois dernier
    </p>
</template>

<script>
export default {
    name: 'ProgressText',
    props: ["value"]
}
</script>